import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/inertia-vue3';
import {InertiaProgress} from '@inertiajs/progress';

InertiaProgress.init();

createInertiaApp({
    resolve: name => import(`./../modules/${name}`),
    title: title => (title ? `${title} - Mon CA` : 'Mon CA'),
    setup({el, App, props, plugin}) {
        createApp({render: () => h(App, props)})
            .use(plugin)
            .mixin({methods: {route}})
            .mount(el);
    },
});
